import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Typography,
} from '@material-ui/core';
import { createStyles, StyleRules, WithStyles, withStyles } from '@material-ui/core/styles';
import AssignmentRoundedIcon from '@material-ui/icons/AssignmentRounded';
import BallotRoundedIcon from '@material-ui/icons/BallotRounded';
import BusinessIcon from '@material-ui/icons/Business';
import CreditCardRoundedIcon from '@material-ui/icons/CreditCardRounded';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HomeIcon from '@material-ui/icons/Home';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import SmsRoundedIcon from '@material-ui/icons/SmsRounded';
import classNames from 'classnames';
import { navigate } from 'gatsby';

import { initialize } from '../actions/userActions';
import Header from '../components/ConsultantHeader';
import Footer from '../components/Footer';
import Loading from '../components/Loading';
import LoadingLogo from '../components/LoadingLogo';
import Notification from '../components/Notification';
import { AppState } from '../reducers';
import { loadingEndAction } from '../reducers/loadingReducer';
import { activeCompanyVerificationRegister } from '../reducers/otherStatus';
import debug from '../utils/debug';

const styles = (): StyleRules =>
  createStyles({
    noConsultant: {
      marginTop: '10%',
      height: '60vh',
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.3)',
      zIndex: 10,
    },
    noConsultantItem: {
      margin: '1%',
    },
    container: {
      display: 'flex',
      width: '100%',
      height: '100%',
    },
    childMenuItem: {
      border: '1px solid #9b9bbe',
      borderRadius: '10px',
      margin: '2px',
      padding: '4px',
      fontSize: '0.75rem',
      cursor: 'pointer',
      color: '#3f424f',
      '&:hover': {
        backgroundColor: '#9b9bbe',
      },
    },
    leftMenu: {
      marginTop: '64px',
      width: '210px',
      padding: '20px',
      textAlign: 'left',
      background: '#ffffff',
      color: '#3f424f',
      position: 'fixed',
      height: '100%',
    },
    content: {
      marginLeft: '210px',
      width: 'calc(100% - 210px)',
      marginTop: '64px',
    },
    menuStart: {
      margin: '1.2rem 0',
    },
    iconMargin: {
      margin: '0 0.5rem',
    },
    menuButton: {
      margin: '0.5rem 0',
      color: '#3f424f',
      display: 'flex',
      justifyContent: 'left',
      fontSize: '1rem',
    },
    menuButtonActive: {
      backgroundColor: '#9b9bbe',
    },
  });

const CompanyLayout = ({ children, classes }: Props): React.ReactElement => {
  debug('Loading admin layout');
  const { id: uid, userType, company: userCompany } = useSelector(
    (appState: AppState) => appState.user
  );
  // const { id: companyId } = useSelector((appState: AppState) => appState.company);
  const companyMenuState = useSelector((appState: AppState) => appState.companyMenuReducer);
  const [fold, setFold] = React.useState(true);
  const isLoading = useSelector((state: AppState) => state.loading.isLoading);
  const { isVisible, severity, message } = useSelector((state: AppState) => state.notification);

  const dispatch = useDispatch();
  const companyWhite = (): boolean => {
    if (location && location.pathname === '/company/verification') return true;
    return false;
  };

  useEffect(() => {
    dispatch(initialize(userType));
    dispatch(loadingEndAction());
  }, []);

  const NoCompany = (
    <Grid container direction="column" className={classes.noConsultant}>
      <Grid item className={classes.noConsultantItem}>
        <Typography style={{ fontSize: 25 }}>你还不是企业，是否要创建成为企业？</Typography>
        {/* <Typography variant="subtitle2" align="center">
          <Checkbox
            checked={checked}
            onChange={() => {
              setChecked(!checked);
            }}
            color="primary"
          />
          请确认阅读 《
          <span>
            <a
              href="https://www.fintegrity.cn/privacy"
              target="_blank"
              className={classes.a}
              rel="noopener noreferrer"
            >
              查看隐私政策
            </a>
          </span>
          》并同意遵守傅通自由职业平台的用户协议和隐私政策
        </Typography> */}
      </Grid>
      <Grid item className={classes.noAdminItem}>
        <Button
          onClick={() => {
            dispatch(
              activeCompanyVerificationRegister({
                showCompanyVerification: true,
              })
            );
            navigate('/company/verification');
          }}
          variant="contained"
          color="primary"
          style={{
            width: '20%',
          }}
        >
          创建成为企业用户
        </Button>
      </Grid>
    </Grid>
  );

  if (uid && children) {
    return (
      <>
        <Helmet>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
          />
          <link rel="stylesheet" href="https://fonts.loli.net/icon?family=Material+Icons" />
        </Helmet>
        <div style={{ position: 'fixed', zIndex: '99999' }}>
          <Header />
        </div>
        <div className={classes.container}>
          {userCompany?.id && (
            <div className={classes.leftMenu} style={{ zIndex: '99999' }}>
              <div className={classes.menuStart}>
                <Button
                  fullWidth={true}
                  className={classNames(classes.menuButton, {
                    [classes.menuButtonActive]: companyMenuState.menuStatus?.home,
                  })}
                  aria-label="Job request list"
                  onClick={() => {
                    navigate('/company');
                  }}
                >
                  <HomeIcon className={classes.iconMargin} />
                  <span>首页</span>
                </Button>

                <Button
                  fullWidth={true}
                  className={classNames(classes.menuButton, {
                    [classes.menuButtonActive]: companyMenuState.menuStatus?.publishProject,
                  })}
                  aria-label="Job request list"
                  onClick={() => {
                    navigate('/company/publishProject');
                  }}
                >
                  <LibraryAddIcon className={classes.iconMargin} />
                  <span>发布项目</span>
                </Button>

                <Button
                  fullWidth={true}
                  className={classNames(classes.menuButton, {
                    [classes.menuButtonActive]: companyMenuState.menuStatus?.projectList,
                  })}
                  aria-label="Job request list"
                  onClick={() => {
                    navigate('/company/projectList');
                  }}
                >
                  <LibraryBooksIcon className={classes.iconMargin} />
                  <span>项目列表</span>
                </Button>

                <Button
                  fullWidth={true}
                  className={classNames(classes.menuButton, {
                    [classes.menuButtonActive]: companyMenuState.menuStatus?.biddingList,
                  })}
                  aria-label="Job request list"
                  onClick={() => {
                    navigate('/company/bidding');
                  }}
                >
                  <AssignmentRoundedIcon className={classes.iconMargin} />
                  <span>申请浏览 </span>
                </Button>
                <Button
                  fullWidth={true}
                  className={classNames(classes.menuButton, {
                    [classes.menuButtonActive]: companyMenuState.menuStatus?.pendingProject,
                  })}
                  aria-label="Job request list"
                  onClick={() => {
                    navigate('/company/pendingProject');
                  }}
                >
                  <BallotRoundedIcon className={classes.iconMargin} />
                  <span>进行中项目</span>
                </Button>
                <Button
                  fullWidth={true}
                  className={classes.menuButton}
                  aria-label="Job request list"
                  onClick={() => {
                    // navigate('/admin/pendingProjectList');
                  }}
                >
                  <CreditCardRoundedIcon className={classes.iconMargin} />
                  <span>项目结算</span>
                </Button>

                <Button
                  fullWidth={true}
                  className={classes.menuButton}
                  aria-label="Job request list"
                  onClick={() => {
                    // navigate('/admin/jobRequest');
                  }}
                >
                  <SmsRoundedIcon className={classes.iconMargin} />
                  <span>项目评价</span>
                </Button>
              </div>
              <Accordion
                expanded={fold}
                style={{ width: '168px', boxShadow: '0px 0px 0px 0px', paddingTop: '0' }}
                onChange={() => {
                  setFold(!fold);
                }}
              >
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                  expandIcon={<ExpandMoreIcon />}
                >
                  <BusinessIcon style={{ marginRight: '0.5rem' }} />
                  公司管理
                </AccordionSummary>
                <AccordionDetails style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <span
                    className={classes.childMenuItem}
                    onClick={e => {
                      e.preventDefault();
                      navigate('/company/verification');
                    }}
                  >
                    基本信息
                  </span>
                  <span
                    className={classes.childMenuItem}
                    onClick={e => {
                      e.preventDefault();
                    }}
                  >
                    公司介绍
                  </span>
                  <span
                    className={classes.childMenuItem}
                    onClick={e => {
                      e.preventDefault();
                      navigate('/company/info');
                    }}
                  >
                    实名认证
                  </span>
                  <span
                    className={classes.childMenuItem}
                    onClick={e => {
                      e.preventDefault();
                    }}
                  >
                    套餐管理
                  </span>
                </AccordionDetails>
              </Accordion>
            </div>
          )}

          {(userCompany?.id || companyWhite()) && <div className={classes.content}>{children}</div>}
          {!userCompany?.id && !companyWhite() && NoCompany}
        </div>

        <Footer />
        {isLoading && <Loading />}
        {isVisible && <Notification isVisible={isVisible} severity={severity} message={message} />}
      </>
    );
  }

  return <LoadingLogo />;
};

export interface Props extends WithStyles<typeof styles> {
  children?: React.ReactElement | React.ReactElement[];
  verificationHide?: boolean;
}

export default withStyles(styles)(CompanyLayout);
